<template>
<v-col cols="12" class="home-content">
  
  <v-row>
    <template v-if="post != null">
      <v-col cols="12" sm="11" md="11" lg="8" 
            class="mx-auto text-left article pt-0 mt-0" 
            style="max-width: 1000px; min-height:700px;">

        <v-toolbar class="font-hand mt-0" color="transparent" elevation="0">
          
          <v-btn  icon outlined class="" :to="'/book'" title="retour au sommaire"><v-icon>mdi-pound</v-icon></v-btn>
          
          <v-spacer/>
          
          <template v-if="$store.getters['auth/isAdmin']">
            <v-btn color="blue" icon outlined class="ma-1 mr-5" 
                  :to="'/admin/create-entity/post/'+(post._id)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <v-btn v-if="post.position > 1" icon outlined class="mr-3" :to="'/post/'+(post.position-1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <!-- <span># <b>J-{{ getCountDay(post) }}</b></span> -->
          <!-- <span class="font-dancing ml-4 d-none d-sm-inline" style="font-size:19px;">{{ getDateStory(post) }}</span>
          <span class="font-dancing ml-4 d-sm-none" style="font-size:19px;">{{ getDateStoryShort(post) }}</span> -->

          <v-btn  icon outlined class="ml-3" :to="'/post/'+(post.position+1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
          
        </v-toolbar>

        <v-divider class="my-5"/>
        
        <template v-if="post.youtubeURL != null && post.youtubeURL != ''">
          <div class="d-none d-sm-block">
            <iframe width="100%" height="600" :src="post.youtubeURL" 
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
            </iframe>
          </div>
          <div class="d-sm-none">
            <iframe width="100%" height="300" :src="post.youtubeURL" 
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
            </iframe>
          </div>
        </template>

        <br>
        <template v-if="$vuetify.breakpoint.width > 960"><br><br></template>
        <h2 @click="initData" class="title-sommaire font-hand mb-5">
          #{{ post.position }} <span class="ml-3">{{ post.title }}</span>
        </h2><br>
        <p class="font-papyrus paragraf mt-2" v-html="nl2br(post.text)"></p>

        <v-col class="text-center mt-5">
          <v-toolbar elevation="0" color="transparent" class="mt-5">
            <v-spacer/>
            <v-btn v-if="post.position > 1" icon outlined class="ma-3" :to="'/post/'+(post.position-1)">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <template v-if="$store.getters['auth/isAdmin']">
              <v-btn color="blue" icon outlined class="ma-3" :to="'/admin/create-entity/post/'+(post._id)">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <v-btn icon outlined class="ma-3" :to="'/post/'+(post.position+1)">
             <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-spacer/>
          </v-toolbar>
        </v-col>

      </v-col>
    </template>
  </v-row>

  <PostRow/>
</v-col>
</template>

<style>

@media (max-width: 960px) {

  .paragraf{
    font-size:17px;
  }
  .article .title-sommaire{
    font-size:40px;
  }
}

@media (max-width: 600px) {

  .paragraf{
    font-size:14px;
  }
  .article .title-sommaire{
    font-size:30px !important;
    line-height:35px !important;
    margin-top: 0px !important;
  }
}

</style>

<script>

//import axios from 'axios'
//import router from '../router/router'
import core from '../../plugins/core.js'

//import Post from '@/components/Post.vue'
import PostRow from '@/components/main-layout/PostRow.vue'
import '@/assets/css/home.css';
import '@/assets/css/blog.css'

export default {
  name: 'post',
  components: { PostRow },
  data: () => ({
    post: null,
    
  }),
  async mounted(){
    this.initData()
  },
  methods: {
    async initData(){
      //if(this.$store.state.app.data.blogPost.length == 0)
          await this.$store.dispatch('app/fetchEntities', { entityType: "blogPost", query: { public: true }, sort: 'position' })

      this.$store.state.app.data.blogPost.forEach(post => {
        if(post.position == this.$route.params.postId 
        && post.categories[0].name == "journal de bord")
          this.post = post
      });
    },
    getCountDay(post){
      return core.getCountDay(post)
    },
    // getDateStory(post){
    //   return core.getDateStory(post)
    // },
    // getDateStoryShort(post){
    //   return core.getDateStoryShort(post)
    // },
    nl2br: function(str, is_xhtml){
      return core.nl2br(str, is_xhtml)
    },

  },
  computed: {
    
    
  },
  watch: {
    '$store.state.app.data.post' : { immediate: true, 
      async handler () {
          this.initData()
      }
    },
    '$route.params.postId' : { immediate: true, 
      async handler () {
          this.initData()
      }
    },
  }
}
</script>
