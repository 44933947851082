<template>
  <v-row style="margin-bottom:100px;">
    <v-col cols="12" class="mx-auto px-5" v-if="$store.state.app.data.blogPost.length > 0">
        <br><br>
        <v-divider/>
        <br><br>
      <v-row>
        <template v-for="(post, p) in $store.state.app.data.blogPost">
          <v-col cols="12" sm="12" md="6" lg="4" :key="p"  
                  v-if="post.categories[0].name == 'journal de bord'"
                  class="pa-0 px-2 mb-5 font-papyrus">
            <CardPostSM :post="post"></CardPostSM>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>


<script>
  import CardPostSM from '@/components/main-layout/CardPostSM.vue'
  export default {
    name: "PostRow",
    components: {
      CardPostSM
    },
  }
</script>