<template>
  <v-card elevation="0" class="card-post-paper" :to="'/post/' + post.position">
    
    <v-col cols="12" class="pt- px-4">
      
      <b class="font-papyrus primary--text" style="font-size:22px;">
        {{ post.position }}# 
        <!-- <small class="mr-5">J-{{ getCountDay(post) }}</small> -->
      </b>

      <span class="font-main primary--text" style="font-size:34px;line-height:10px">{{ post.title }}</span>

      <br>

      <div style="height:140px; overflow:hidden;font-size:20px;line-height:26px;" class="mt-4">
        <small>{{ post.text.substr(0, 200) }}...</small>
      </div>
      
      <br>

      <small class="grey--text" v-if="post.created != null">
        Créé 
        <time-ago :datetime="post.created" style="font-size:11px !important;"
                  :refresh="60" long :locale="$vuetify.lang.current">
        </time-ago>
      </small>
      
      <small class="grey--text" v-if="post.updated != null">
        <span v-if="post.created != null"> - </span>
        Modifié
        <time-ago :datetime="post.updated" style="font-size:11px !important;"
                  :refresh="60" long :locale="$vuetify.lang.current">
        </time-ago>
      </small>

    </v-col>


  </v-card>
</template>

<style>
.btn-action1{
  padding:8px;
  border-radius: 4px;
  color: white !important;
  background-color: #009688;
  text-decoration:none;
}
.mail-header{
  text-align:center;
  border-bottom: 1px solid #dfdfdf;
}
</style>


<script>
import core from '../../plugins/core.js'
import {TimeAgo} from "vue2-timeago"
//import { i18n } from 'vue-lang-router';

export default {
    name: "card-post",
    components: {
      TimeAgo
    },
    props: ['post'],
    data: () => ({
    }),
    mounted: function(){
    },
    methods: {
        
        //baseUrl(){ return core.baseUrl() },
        
        getCountDay(post){
          return core.getCountDay(post)
        },
        getDateStory(post){
          return core.getDateStory(post)
        },
        getDateStoryShort(post){
          return core.getDateStoryShort(post)
        }
    },
    computed:{
      
      colorStatus(){ 
        if(this.post.public == false) return 'blue'
        if(this.post.public) return 'green'
        return 'grey'
      }
    }
};
</script>